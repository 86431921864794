import React, { useContext, useEffect, useState } from 'react';
import { /* Redirect, */ withRouter } from 'react-router-dom';

import config from '../config';
import { AuthContext } from '../contexts/auth';
import { SetupContext } from '../contexts/setup';
// reserved / import { addRedirect } from '../utils/misc';
import Splash, { SplashBody } from '../components/splash';

function SigninOAuth({ location }) {
	const { providers } = useContext(SetupContext);
	const { isAuthenticated, authenticate, token } = useContext(AuthContext);
	const [ state, setState ] = useState();
	const [ errorState, setErrorState ] = useState(); // workaround, see below
	const signinAction = config.server.base + '/api/oauth/authorize' + location.search;

	useEffect(() => {
		// isAuthenticated && window.mixpanel?.track('OAuth process started', {distinct_id: user.id});
	}, [true]);

	useEffect(() => {
		location.search && setState({searchParams: Object.fromEntries(new URLSearchParams(location.search))});
	}, [location.search]);

	const onSubmit = () => {
		setErrorState();

		fetch(config.server.base + '/api/oauth/register', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(Object.fromEntries(new URLSearchParams(location.search))),
		}).then(async response => {

			// reserved / if (response.ok) { // alsways "false" :( opaque?
			if (response.status < 400) { // weak?
				const data = await response.json();
				authenticate(data.token);

				// reserved
				// window.mixpanel?.track('OAuth process started', {distinct_id: user.id});
			}

			else {
				setErrorState({message: (await response.json())?.message || 'Error'});
			}

		}).catch(err => {
			setErrorState({message: err?.toString?.() || 'Error'});
		});
	};

	// obsoleted
	// if (!isAuthenticated) {
	// 	return <Redirect to={addRedirect('/signin', '/signin/oauth' + location.search)} />;
	// }

	return state ? (
		<Splash>
			{
				isAuthenticated ?
					<SplashBody>
						<h3 className="text-center mb-3">{'TrendSpider' || 'The app'} would like to:</h3>

						<ul className="mt-4">
							<li>Read list of brokerage accounts connected.</li>
							<li>Submit orders to any of them by your request.</li>
							<li>Obtain list of holdings from any of them by your request.</li>
						</ul>

						<form className="mt-4" action={signinAction} method="post">
							<input type="hidden" name="token" value={token} />
							<button type="submit" className="btn btn-primary w-100">Grant access</button>
						</form>
					</SplashBody>
					:
					<SplashBody>
						<h3 className="text-center mb-3">Welcome to SignalStack</h3>

						<p>
							SignalStack is an order router developed by TrendSpider. Connect your {state.searchParams.provider_name ? providers[state.searchParams.provider_name].title : 'broker'} account to SignalStack in order to trade from TrendSpider, either manually or automatically.
						</p>

						<p>
							Manual trading via SignalStack is free, forever.
						</p>

						<form className="mt-4" onSubmit={event => { event.preventDefault(); onSubmit(); }} method="post">
							<input type="hidden" name="token" value={token} />
							<button type="submit" className="btn btn-outline-primary w-100">Login to SignalStack<br/><span className="opacity-50 text-truncate">as {state.searchParams.email}</span></button>

							<div className="tttext-secondary tttext-center mt-3">
								Not registered yet?
							</div>

							<button type="submit" className="btn btn-primary w-100 mt-2">Create an account<br/><span className="opacity-50 text-truncate">as {state.searchParams.email}</span></button>

							{
								errorState && (
									<div className="alert alert-danger mt-3" role="alert">
										{errorState.message}
									</div>
								)
							}
						</form>

						{/*
						<div className="mt-5" style={{opacity: '20%'}}>
							<form className="mt-4" action={signinAction} method="post">
								<input type="hidden" name="token" value={token} />
								<button type="submit" className="btn btn-primary w-100">Authorize</button>

								<div className="text-secondary text-center mt-1">
									A user account to be created if not yet
								</div>
							</form>
						</div>
						*/}
					</SplashBody>
			}


			{/*
			{
				<div className="mt-4 text-center text-secondary">
					Something wrong? <Link to="/xxx" title="xxx" className="">Cancel</Link>
				</div>
			}
			*/}
		</Splash>
	) : false;
}

export default withRouter(SigninOAuth);
